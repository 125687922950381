export interface IUserSession {
  user: string;
  token: string;
  urlPhoto: string;
  profile: any;
}

export const TypeSession = {
  keyUser: "@user",
};
